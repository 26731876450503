/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { koivu, koivu2, kanki, pilkonta, pilkonta2, saha } from '../album';

const images = [
    { src: koivu, alt: 'koivu' },
    { src: koivu2, alt: 'koivu' },
    { src: kanki, alt: 'koivu' },
    { src: pilkonta, alt: 'koivu' },
    { src: pilkonta2, alt: 'koivu' },
    { src: saha, alt: 'koivu' }
  ];

export default () => (
    <Carousel autoPlay className="img-carousel">
        {images.map((image, index) => (
            <div key={index}>
                <img src={image.src} alt={image.alt} />
            </div>
        ))}
  </Carousel>
);