import React from 'react';
import content from './content.json';

const Footer = () => {
  return (
    <>
    <footer className="footerr" id="contact">

      <div className="footer">
        
          <h3>{content.footerContent.footerHeader}</h3>
          <a href="https://www.vero.fi/henkiloasiakkaat/verokortti-ja-veroilmoitus/vahennykset/kotitalousvahennys/">{content.footerContent.footerLink}</a>
         
      </div>

      <div className="mid">
        
          <i>{content.footerContent.footerCopyRight}</i>
        
      </div>

      <div className="right">
        
          <h3>{content.footerContent.contact}</h3>
          <p>{content.footerContent.name}</p>
          <p>{content.footerContent.phone}</p>
          <p>{content.footerContent.email}</p>
          <p>{content.footerContent.company}</p>
         
      </div>
      
    </footer>
    <div className="footer-infos"><i>Verkkosivut | WT</i></div>
    </>
  )
}

export default Footer;