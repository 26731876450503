import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import Slide from '@mui/material/Slide'; // Import Slide component
import useScrollTrigger from '@mui/material/useScrollTrigger'; // Import useScrollTrigger hook

import { HiOutlinePencil, HiOutlineViewList } from "react-icons/hi";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <HideOnScroll>
      <AppBar style={{ background: "#172601"}}>
        {/* <AppBar position="static" style={{ background: "#172601"}}></AppBar> */}
        <Container maxWidth="l">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { 
              xs: 'flex', 
              md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                  <MenuItem className="menu-item" sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-around", height: "10rem"}} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center"><a href="#infos"><i className="mobile-services"><HiOutlinePencil /></i>Palvelut</a></Typography>
                    <Typography textAlign="center"><a href="#contact"><i className="mobile-faq"><HiOutlineViewList /></i>Yhteystiedot</a></Typography>
                  </MenuItem>
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 0 }} width="220px">
              <Typography><span className="logo-text">NTU</span></Typography>
            </Box>
            
            
            <Box width="100%" className="nav" display="flex" alignItems="center" justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
                  
                  <Button
                      onClick={handleCloseNavMenu}
                      sx={{ fontSize: "1.2rem", color: "white", justifyContent: "center", fontFamily: 'Saira Condensed, sans-serif', marginLeft: "0.5rem", marginRight: "0.5rem" }}
                  >
                    <a href="#infos"><i className="logo-services"><HiOutlinePencil/></i> Palvelut</a>
                  
                  </Button>

                  
                      
                      <span style={{ margin: "0px 8px 0px 8px"}}>|</span>
                  

                  <Button
                      onClick={handleCloseNavMenu}
                      sx={{ fontSize: "1.2rem", color: "white", fontFamily: 'Saira Condensed, sans-serif', marginLeft: "0.5rem", marginRight: "0.5rem" }}
                  >
                  
                  <a href="#contact"><i className="logo-ffa"><HiOutlineViewList /></i>Yhteystiedot </a>
                  </Button>
              
              
            </Box>


            <Box className="empty-box" sx={{ flexGrow: 0 }} width="220px">
            </Box>

            
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}
export default ResponsiveAppBar;