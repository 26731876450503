import React from 'react';
import content from './content.json';

const Prices = () => {
  return (
    <div className="price-content">
        <div className="price-content-container">
            <div className="price-content-text">
                <h1>{content.pricingContent.pricingTitle}</h1>
                <p>{content.pricingContent.pricingContent}</p>
                <p>{content.pricingContent.pricingAddition}</p>
            </div>

            
        </div>
    </div>
  )
}

export default Prices