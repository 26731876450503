import './App.css';
import Carousel from './components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Secondary from './components/Secondary';
import Footer from './components/Footer';
import ResponsiveAppBar from './components/AppBar';
import Infos from './components/Infos';
import Prices from './components/Prices';

function App() {
  return (
    <>
      <ResponsiveAppBar />
      <Infos />
      <Prices />
      <div className="App">
        <Carousel />
      </div> 
      <Secondary />
      <Footer />
    </>
  );
}

export default App;
