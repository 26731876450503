import React from 'react'

const Secondary = () => {
  return (
    <div className="secondary">
        {/* <img src={forest} alt="My SVG" /> */}
        <div className="secondary-container">
            
        </div>
    </div>
  )
}

export default Secondary