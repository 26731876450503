import React from 'react';
import { bg, hero, cover1, cover2 } from '../album';
import content from './content.json';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';

const Infos = () => {

  const images = [hero, cover1, cover2];

  const randomIndex = Math.floor(Math.random() * images.length);

  const selectedImage = images[randomIndex];

  return (
    <>
    <div id="infos">
    <div className="hero-bg-img">
                <img src={selectedImage} alt="hero-bg-img" />
    </div>

        <div className="main-content">
            
            <div className="main-content-container">
                <div className="main-content-text">
                    <h1>{content.mainContent.mainTitle}</h1>
                    <p><b>{content.mainContent.mainContent}</b></p>
                    <ul className="centered-list">
                        <li><i>{content.serviceContent.serviceOne}</i></li>
                    </ul>
                    <br />
                    <p><b>{content.mainContent.mainAddition}</b></p>
                    <ul>
                        <li><i>{content.serviceContent.serviceTwo}</i></li>
                    </ul>
                    <br/>
                    <p><b>{content.serviceContent.serviceTitle}</b></p>
                    <ul>
                        <li><i>{content.serviceContent.serviceThree}</i></li>
                    </ul>
                    <br/>
                    <br/>
                    <div className="btn-container" style={{display: "flex", flexDirection: "column"}}>
                        <a href="mailto:example@example.com"><button style={{marginBottom: "2px"}} className="contact-btn">Ota yhteyttä <i><HiOutlineMail /></i></button></a>
                        <a href="tel:045-1508282"><button style={{marginTop: "2px"}} className="contact-btn-phone">Ota yhteyttä <i><HiOutlinePhone /></i></button></a>
                    </div>
                    
                    
                </div>

                <div className="main-content-img">

                    <div className="first-row">
                        <img className="first-row-picture" src={bg} alt="pic" />         
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Infos;